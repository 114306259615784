import { CHANGE_SCROLLTO_ROUTE, ChangeScrollToRouteTypes, ScrollToRoute } from './types';

export const initialScrollToRouteState: ScrollToRoute = {
    scrollTo: null,
    home: null,
};

export const scrollToReducer = (state = initialScrollToRouteState, action: ChangeScrollToRouteTypes): ScrollToRoute => {
    switch (action.type) {
        case CHANGE_SCROLLTO_ROUTE:
            return action.payload;
        default:
            return state;
    }
};
