import { Spinner } from 'react-bootstrap';

import { Theme, ThemeTypes } from '../../../store/theme/types';

export interface SpinnerProps {
    theme: Theme;
    animation: 'grow' | 'border';
}

const AppSpinner = (props: SpinnerProps): JSX.Element => {
    const { theme, animation } = props;
    const variant = theme === ThemeTypes.light ? ThemeTypes.dark : ThemeTypes.light;
    return <Spinner animation={animation} variant={variant} />;
};

export default AppSpinner;
