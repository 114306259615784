export interface Post {
    author: string;
    categories: string[];
    content: string;
    description: string;
    enclosure: { [key: string]: any };
    guid: string;
    link: string;
    pubDate: string;
    thumbnail: string;
    title: string;
}

export interface PostsState {
    loading: boolean;
    posts: Post[];
    error: string;
}

export const LOAD_POSTS = '[Posts Section] LOAD_POSTS';
export const LOAD_POSTS_SUCCESS = '[Posts Section] LOAD_POSTS_SUCCESS';
export const LOAD_POSTS_FAIL = '[Posts Section] LOAD_POSTS_FAIL';

export interface LoadPostsAction {
    type: typeof LOAD_POSTS;
}

export interface LoadPostsSuccess {
    type: typeof LOAD_POSTS_SUCCESS;
    payload: Post[];
}

export interface LoadPostsFail {
    type: typeof LOAD_POSTS_FAIL;
    payload: string;
}

export type PostActionTypes = LoadPostsAction | LoadPostsSuccess | LoadPostsFail;
