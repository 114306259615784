import { LanguageActionTypes, LanguageState, LOAD_LITERALS } from './types';

export const initialLanguageState = (): LanguageState => ({
    literals: {},
});

export const languageReducer = (state = initialLanguageState(), action: LanguageActionTypes): LanguageState => {
    switch (action.type) {
        case LOAD_LITERALS:
            return {
                literals: action.payload,
            };
        default:
            return state;
    }
};
