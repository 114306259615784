import './SocialLinks.scss';

import { FaGithubAlt, FaLinkedinIn, FaStackOverflow } from 'react-icons/fa';

import { Theme } from '../../../store/theme/types';

export interface SocialLinksProps {
    theme: Theme;
}

type Props = SocialLinksProps;

const SocialLinks = (props: Props): JSX.Element => {
    const { theme } = props;
    const linkClickHandler = (link: string): void => {
        window.open(link, '_blank', 'noopener');
    };

    return (
        <div className="SocialLinks d-flex justify-content-between">
            <FaStackOverflow
                data-testid="stackoverflow"
                className={`${theme}-theme-text`}
                onClick={(): void => linkClickHandler('https://stackoverflow.com/users/7365461/alif50')}
            />

            <FaLinkedinIn
                data-testid="linkedin"
                className={`${theme}-theme-text`}
                onClick={(): void => linkClickHandler('https://www.linkedin.com/in/alif50/')}
            />

            <FaGithubAlt
                data-testid="github"
                className={`${theme}-theme-text`}
                onClick={(): void => linkClickHandler('https://github.com/AliF50')}
            />
        </div>
    );
};

export default SocialLinks;
