import './Switch.scss';

import { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';

interface SwitchProps {
    label: string;
    id?: string;
    onSwitchClick: (event: ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
}

const Switch = ({ label, id, onSwitchClick, checked }: SwitchProps): JSX.Element => {
    return (
        <div className="Switch">
            <Form.Check
                data-testid="toggle"
                type="switch"
                label={label}
                id={id}
                onChange={onSwitchClick}
                checked={checked}
            />
        </div>
    );
};

export default Switch;
