import { LOAD_POSTS, LOAD_POSTS_FAIL, LOAD_POSTS_SUCCESS, PostActionTypes, PostsState } from './types';

export const initialPostsState = (): PostsState => ({
    loading: false,
    posts: [],
    error: '',
});

export const postsReducer = (state = initialPostsState(), action: PostActionTypes): PostsState => {
    switch (action.type) {
        case LOAD_POSTS:
            return {
                ...initialPostsState(),
                loading: true,
            };
        case LOAD_POSTS_SUCCESS:
            return {
                ...initialPostsState(),
                posts: action.payload,
            };
        case LOAD_POSTS_FAIL:
            return {
                ...initialPostsState(),
                error: action.payload,
            };
        default:
            return state;
    }
};
