export interface ContactMessage {
    firstName: string;
    lastName: string;
    email: string;
    message: string;
}
export interface ContactState {
    loading: boolean;
    success: string;
    error: string;
}

export const SEND_CONTACT_EMAIL = '[Contact] SEND_CONTACT_EMAIL';
export const SEND_CONTACT_EMAIL_SUCCESS = '[Contact] SEND_CONTACT_SUCCESS';
export const SEND_CONTACT_EMAIL_FAIL = '[Contact] SEND_CONTACT_FAIL';
export const RESET_SEND_CONTACT_EMAIL = '[Contact] RESET_SEND_CONTACT_EMAIL';

export interface SendContactEmail {
    type: typeof SEND_CONTACT_EMAIL;
}

export interface SendContactEmailSuccess {
    type: typeof SEND_CONTACT_EMAIL_SUCCESS;
    payload: string;
}

export interface SendContactEmailFail {
    type: typeof SEND_CONTACT_EMAIL_FAIL;
    payload: string;
}

export interface ResetSendContactEmail {
    type: typeof RESET_SEND_CONTACT_EMAIL;
}

export type SendContactTypes =
    | SendContactEmail
    | SendContactEmailSuccess
    | SendContactEmailFail
    | ResetSendContactEmail;
