export interface LanguageState {
    literals: { [key: string]: any };
}

export const LOAD_LITERALS = '[LanguageToggle] LOAD_LITERALS';

export interface ChangeLanguageAction {
    type: typeof LOAD_LITERALS;
    payload: { [key: string]: any };
}

export type LanguageActionTypes = ChangeLanguageAction;
