import './ScrollToTop.scss';

import { useEffect, useState } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store';

const ScrollToTop = (): JSX.Element => {
    const [showScroll, setShowScroll] = useState(false);

    const { backToTheTop } = useSelector((state: RootState) => state.language.literals.scrollToTop);

    const checkScrollTop = (): void => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false);
        }
    };

    const scrollToTop = (): void => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return (): void => {
            window.removeEventListener('scroll', checkScrollTop);
        };
    });

    return (
        <FaArrowCircleUp
            title={backToTheTop}
            className="ScrollToTop"
            onClick={scrollToTop}
            color="#0bceaf"
            style={{ display: showScroll ? 'block' : 'none' }}
        />
    );
};

export default ScrollToTop;
