import './LanguageSelection.scss';

import { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import loadLang, { Languages, LanguageTypes } from '../../../i18n';
import { RootState } from '../../../store';
import { loadLiterals } from '../../../store/strings/actions';

const LanguageSelection = (): JSX.Element => {
    const selectedLanguage = localStorage.getItem('aliPortfolioLanguagePreference') || Languages.english.code;
    const languageSelectionStrings = useSelector((state: RootState) => state.language.literals.languageSelection);
    const dispatch = useDispatch();

    const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        localStorage.setItem('aliPortfolioLanguagePreference', event.target.value);
        const lang = loadLang(event.target.value as LanguageTypes);
        dispatch(loadLiterals(lang));
    };

    return (
        <div className="LanguageSelection">
            <Form.Select
                data-testid="language-select"
                id="language-select"
                onChange={handleLanguageChange}
                value={selectedLanguage}
            >
                {Object.keys(Languages).map((language) => (
                    <option key={language} value={Languages[language].code}>
                        {languageSelectionStrings[Languages[language].fullName]}
                    </option>
                ))}
            </Form.Select>
        </div>
    );
};

export default LanguageSelection;
