import './Footer.scss';

import { useSelector } from 'react-redux';

import { RootState } from '../../../store';
import SocialLinks from '../SocialLinks/SocialLinks';

const Footer = (): JSX.Element => {
    const currentYear = new Date().getFullYear();
    const theme = useSelector((state: RootState) => state.theme.selectedTheme);
    const allRightsReserved = useSelector((state: RootState) => state.language.literals.footer.allRightsReserved);
    return (
        <div
            id="footer2"
            className={`Footer ${theme}-theme-bg d-flex justify-content-around align-items-center flex-wrap`}
        >
            <small className={`footer-text ${theme}-theme-text`}>
                {allRightsReserved} - Ali Hassan - {currentYear}
            </small>
            <div className="social">
                <SocialLinks theme={theme} />
            </div>
        </div>
    );
};

export default Footer;
