import './FallBackLoading.scss';

import { Theme } from '../../../store/theme/types';
import AppSpinner from '../AppSpinner/AppSpinner';

export interface FallBackLoadingProps {
    theme: Theme;
}
const FallBackLoading = (props: FallBackLoadingProps): JSX.Element => {
    const { theme } = props;
    const animation = 'grow';
    return (
        <div className="FallBackLoading d-flex justify-content-center align-items-center">
            <AppSpinner theme={theme} animation={animation} />
        </div>
    );
};

export default FallBackLoading;
