import en from './en.json';
import fr from './fr.json';

export type LanguageTypes = 'en' | 'fr';

interface Language {
    code: LanguageTypes;
    fullName: string;
}

export const Languages: { [key: string]: Language } = {
    english: { code: 'en' as LanguageTypes, fullName: 'english' },
    french: { code: 'fr' as LanguageTypes, fullName: 'french' },
};

const langs: { [key in LanguageTypes]: Record<string, any> } = {
    en,
    fr,
};

export default (lang = 'en' as LanguageTypes): { [key: string]: any } => {
    const localStorageValue = localStorage.getItem('aliPortfolioLanguagePreference') as LanguageTypes;
    if (localStorageValue) {
        return langs[localStorageValue];
    }
    return langs[lang];
};
