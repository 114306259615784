export interface ScrollToRoute {
    scrollTo: string | null;
    home: boolean | null;
}

export const CHANGE_SCROLLTO_ROUTE = '[NavBar] CHANGE_SCROLLTO_ROUTE';

export interface ChangeScrollToRoute {
    type: typeof CHANGE_SCROLLTO_ROUTE;
    payload: ScrollToRoute;
}

export type ChangeScrollToRouteTypes = ChangeScrollToRoute;
