import { CHANGE_THEME, Theme, ThemeActionTypes, ThemeState, ThemeTypes } from './types';

const osTheme =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? ThemeTypes.dark : ThemeTypes.light;
const localStorageTheme = localStorage.getItem('aliPortfolioTheme') as Theme;
const selectedTheme = localStorageTheme ? localStorageTheme : osTheme;

export const initialThemeState: ThemeState = {
    selectedTheme,
};

export const themeReducer = (state = initialThemeState, action: ThemeActionTypes): ThemeState => {
    switch (action.type) {
        case CHANGE_THEME:
            return {
                selectedTheme: action.payload,
            };
        default:
            return state;
    }
};
