import './ThemeToggle.scss';

import { FaMoon, FaSun } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../store';
import { changeTheme } from '../../../store/theme/actions';
import { ThemeTypes } from '../../../store/theme/types';
import Switch from '../Switch/Switch';

const ThemeToggle = (): JSX.Element => {
    const theme = useSelector((state: RootState) => state.theme.selectedTheme);
    const { light, dark } = useSelector((state: RootState) => state.language.literals.themeToggle);
    const dispatch = useDispatch();

    const switchClickHandler = (): void => {
        const selectedTheme = theme === ThemeTypes.light ? ThemeTypes.dark : ThemeTypes.light;
        localStorage.setItem('aliPortfolioTheme', selectedTheme);
        dispatch(changeTheme(selectedTheme));
    };

    const checked = theme === ThemeTypes.dark;

    return (
        <div className="ThemeToggle">
            <div className="theme-toggle-container d-flex align-items-center">
                <FaSun className="sun" color="#FDB813" />
                <div className={`light-theme ${theme}-theme-text`}>{light}</div>
                <Switch
                    id={`theme-switch-${theme}`}
                    label={dark}
                    onSwitchClick={switchClickHandler}
                    checked={checked}
                />
                <FaMoon className="moon" color="#e8e38e" />
            </div>
        </div>
    );
};

export default ThemeToggle;
