import './NavigationBar.scss';

import { useEffect, useRef, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { RootState } from '../../../store';
import { changeScrollToRouteFromNavBar } from '../../../store/scrollRoutes/actions';
import LanguageSelection from '../LanguageSelection/LanguageSelection';
import ThemeToggle from '../ThemeToggle/ThemeToggle';

const NavigationBar = (): JSX.Element => {
    const nonHomeLinks = ['contact'];

    const theme = useSelector((state: RootState) => state.theme.selectedTheme);
    const navbarStrings = useSelector((state: RootState) => state.language.literals.navbar);

    const [expanded, setExpanded] = useState(false);
    const [scrollClassName, setScrollClassName] = useState(false);
    const [activeNavElement, setActiveNavElement] = useState('');

    const navbar = useRef<HTMLDivElement>(null);
    const navItemClickedScroll = useRef<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();
    const currentUrl = location.pathname.replace('/', '');
    const dispatch = useDispatch();

    let scrollTimeout: number;

    useEffect(() => {
        const addHoverStateToScrolledElement = () => {
            // logic for knowing when scrolling is completed
            clearTimeout(scrollTimeout);
            scrollTimeout = window.setTimeout(() => {
                navItemClickedScroll.current = false;
            }, 100);
            // logic for highlighting accurate element when scrolling
            if (!navItemClickedScroll.current && !nonHomeLinks.includes(currentUrl)) {
                const scrollToElements = document.querySelectorAll('.ali-scroll-to-element');
                const visibleElements = Array.from(scrollToElements).filter((el) => {
                    const { top, bottom } = el.getBoundingClientRect();
                    return top < window.innerHeight && bottom >= 0;
                });
                const lastVisibleElement = visibleElements[visibleElements.length - 1];
                if (lastVisibleElement) {
                    const id = lastVisibleElement.id;
                    const activeElement = id.split('-')[0];
                    setActiveNavElement(activeElement);
                }
            }
        };

        window.addEventListener('scroll', addHoverStateToScrolledElement);
        return (): void => {
            window.removeEventListener('scroll', addHoverStateToScrolledElement, false);
        };
    }, [currentUrl]);

    useEffect(() => {
        const handleDomClick = (e: MouseEvent): void => {
            if (navbar.current && navbar.current.contains(e.target as Node)) {
                // inside click
                return;
            }
            setExpanded(false);
        };
        document.addEventListener('mousedown', handleDomClick, false);
        const handleScroll = (): void => {
            // logic for changing nav bar background when scrolled
            if (window.pageYOffset > 0) {
                if (!scrollClassName) {
                    setScrollClassName(true);
                }
            } else {
                if (scrollClassName) {
                    setScrollClassName(false);
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
        return (): void => {
            document.removeEventListener('mousedown', handleDomClick, false);
            window.removeEventListener('scroll', handleScroll, false);
        };
    }, [scrollClassName]);

    const linkClicked = (link: string): void => {
        navItemClickedScroll.current = true;
        setExpanded(false);
        setActiveNavElement(link);
        const currentUrl = location.pathname.replace('/', '');
        const goingToHome = !nonHomeLinks.includes(link);
        dispatch(changeScrollToRouteFromNavBar({ scrollTo: link, home: goingToHome }));
        if (nonHomeLinks.includes(currentUrl)) {
            navigate('/');
        }
    };

    const { home, about, whatIDo, posts, projects, contact } = navbarStrings;

    return (
        <div ref={navbar}>
            <Navbar
                fixed="top"
                expanded={expanded}
                data-testid="navbar"
                bg={theme}
                variant={theme}
                expand="lg"
                className={`portfolio-navbar ${scrollClassName ? 'scrolled' : ''}`}
            >
                <Container>
                    <Navbar.Brand className="h1">Ali Hassan</Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="main-navbar"
                        onClick={(): void => setExpanded(expanded ? false : true)}
                    />
                    <Navbar.Collapse id="main-navbar" className="justify-content-end">
                        <Nav className="ml-auto">
                            <Nav.Link
                                className={activeNavElement === 'home' ? 'active' : ''}
                                onClick={() => linkClicked('home')}
                            >
                                {home}
                            </Nav.Link>
                            <Nav.Link
                                className={activeNavElement === 'about' ? 'active' : ''}
                                onClick={() => linkClicked('about')}
                            >
                                {about}
                            </Nav.Link>
                            <Nav.Link
                                className={activeNavElement === 'whatIDo' ? 'active' : ''}
                                onClick={() => linkClicked('whatIDo')}
                            >
                                {whatIDo}
                            </Nav.Link>
                            <Nav.Link
                                className={activeNavElement === 'posts' ? 'active' : ''}
                                onClick={() => linkClicked('posts')}
                            >
                                {posts}
                            </Nav.Link>
                            <Nav.Link
                                className={activeNavElement === 'projects' ? 'active' : ''}
                                onClick={() => linkClicked('projects')}
                            >
                                {projects}
                            </Nav.Link>
                            <NavLink
                                data-testid="contact"
                                className="nav-link"
                                to="/contact"
                                onClick={() => linkClicked('contact')}
                            >
                                {contact}
                            </NavLink>
                            <ThemeToggle />
                            <LanguageSelection />
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

export default NavigationBar;
