import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { contactReducer } from './contact/reducers';
import { postsReducer } from './posts/reducers';
import { scrollToReducer } from './scrollRoutes/reducers';
import { languageReducer } from './strings/reducers';
import { themeReducer } from './theme/reducers';

export const rootReducer = combineReducers({
    theme: themeReducer,
    language: languageReducer,
    posts: postsReducer,
    scrollTo: scrollToReducer,
    contact: contactReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const configureStore = (): Store<RootState> => {
    const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
    return store;
};

export default configureStore;
