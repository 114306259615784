export type Theme = 'light' | 'dark';
export enum ThemeTypes {
    light = 'light',
    dark = 'dark',
}
export interface ThemeState {
    selectedTheme: Theme;
}

export const CHANGE_THEME = '[ThemeToggle] CHANGE_THEME';

export interface ChangeThemeAction {
    type: typeof CHANGE_THEME;
    payload: Theme;
}

export type ThemeActionTypes = ChangeThemeAction;
