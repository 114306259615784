import {
    ContactState,
    RESET_SEND_CONTACT_EMAIL,
    SEND_CONTACT_EMAIL,
    SEND_CONTACT_EMAIL_FAIL,
    SEND_CONTACT_EMAIL_SUCCESS,
    SendContactTypes,
} from './types';

export const initialContactState = (): ContactState => ({
    loading: false,
    success: '',
    error: '',
});

export const contactReducer = (state = initialContactState(), action: SendContactTypes): ContactState => {
    switch (action.type) {
        case SEND_CONTACT_EMAIL:
            return {
                ...initialContactState(),
                loading: true,
            };
        case SEND_CONTACT_EMAIL_SUCCESS:
            return {
                ...initialContactState(),
                loading: false,
                success: action.payload,
            };
        case SEND_CONTACT_EMAIL_FAIL:
            return {
                ...initialContactState(),
                loading: false,
                error: action.payload,
            };
        case RESET_SEND_CONTACT_EMAIL:
            return initialContactState();
        default:
            return state;
    }
};
