import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import React, { FunctionComponentElement } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import loadLang from './i18n';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import { loadLiterals } from './store/strings/actions';

const store = configureStore();
const lang = loadLang();
store.dispatch(loadLiterals(lang));

const app: FunctionComponentElement<any> = (
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <App />
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
