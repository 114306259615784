import 'react-toastify/dist/ReactToastify.min.css';
import './App.scss';

import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Footer from './shared/components/Footer/Footer';
import NavigationBar from './shared/components/NavigationBar/NavigationBar';
import ScrollToTop from './shared/components/ScrollToTop/ScrollToTop';
const Main = lazy(() => import('./pages/Main/Main'));
const Contact = lazy(() => import('./pages/Contact/Contact'));
import FallBackLoading from './shared/components/FallBackLoading/FallBackLoading';
import { RootState } from './store';

const App = (): JSX.Element => {
    const { selectedTheme: theme } = useSelector((state: RootState) => state.theme);
    const fallBackComponent = <FallBackLoading theme={theme} />;
    return (
        <div className={`App ${theme}-theme-bg`}>
            <NavigationBar />
            <ScrollToTop />
            <div className="main-content">
                <Suspense fallback={fallBackComponent}>
                    <Routes>
                        <Route path="/" element={<Main />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="*" element={<Navigate replace to="/" />} />
                    </Routes>
                </Suspense>
            </div>
            <ToastContainer />
            <Footer />
        </div>
    );
};

export default App;
